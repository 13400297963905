import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../../pages/dashboard";
import ProjectPageUniti from "../../pages/uniti";
import ProjectPageCC from "../../pages/coronacheck";
import ProjectPageCH from "../../pages/coronahealth";
import ProjectPageTyt from "../../pages/trackyourtinnitus";
import About from "../../pages/about";
import Imprint from "../../pages/imprint";
import Privacy from "../../pages/privacy";

const CustomRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/about" element={<About />} />
      <Route path="/imprint" element={<Imprint />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route exact path="/projects/uniti" element={<ProjectPageUniti />} />
      <Route exact path="/projects/tyt" element={<ProjectPageTyt />} />
      <Route exact path="/projects/cc" element={<ProjectPageCC />} />
      <Route exact path="/projects/ch" element={<ProjectPageCH />} />
    </Routes>
  );
};

export default CustomRoutes;
