const Answer = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
      <path
        fill="current"
        d="M17.65 35H30.4q.45 0 .75-.325t.3-.775q0-.45-.3-.775t-.75-.325H17.65q-.5 0-.8.35-.3.35-.3.8 0 .4.3.725.3.325.8.325Zm0-8.3H30.4q.45 0 .75-.325t.3-.825q0-.45-.3-.75t-.75-.3H17.65q-.5 0-.8.325-.3.325-.3.775t.3.775q.3.325.8.325Zm-5.7 16.15q-1.15 0-1.975-.85-.825-.85-.825-1.95V7.95q0-1.1.825-1.95.825-.85 1.975-.85H28.1q.6 0 1.125.225T30.15 6L38 13.85q.4.4.625.925.225.525.225 1.125v24.15q0 1.1-.825 1.95-.825.85-1.975.85Zm16.2-28.55V7.35h-16.2q-.25 0-.425.2t-.175.4v32.1q0 .2.175.4t.425.2h24.1q.25 0 .425-.2t.175-.4v-24.3H29.6q-.6 0-1.025-.425-.425-.425-.425-1.025Zm-16.8-6.95v8.4-8.4V40.65 7.35Z"
      />
    </svg>
  );
};

export default Answer;
