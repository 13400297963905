import "./Grid.css";

const Grid = ({ children }) => {
  return <div className="grid">{children}</div>;
};

const Item = ({ className, children }) => {
  return (
    <div className="grid-item">
      <div className={className}>{children}</div>
    </div>
  );
};

Grid.Item = Item;

export default Grid;
