import { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import ProjectContainer from "../../components/ProjectContainer";
import CustomChart from "../../components/Chart";
import Facts from "../../components/Facts/Facts";

const ProjectPageTyt = () => {
  const [facts, setFacts] = useState();

  useEffect(() => {
    fetch("/assets/data/tyt/tyt.json")
      .then((temp) => {
        return temp.json();
      })
      .then((data) => {
        setFacts(data);
      })
      .catch((error) => {
        // error if file is not written yet
        // console.log(error);
      });
  }, []);

  return (
    <ProjectContainer
      title="Track Your Tinnitus"
      description="With this research project we want to offer a possibility to record the individual fluctuations of tinnitus perception by means of a smartphone app. With the help of your smartphone, you can systematically measure the fluctuations of your tinnitus and find out how they are related to your daily routine and activities. Via a website, you can view the course of your own data and, if necessary, discuss it with a doctor or therapist."
    >
      {facts && <Facts data={facts} />}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Part A - Concentration effects</Accordion.Header>
          <Accordion.Body>
            <CustomChart
              path="/assets/data/tyt/affect_concentration.json"
              initialType="pie"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Part A - Caused pain</Accordion.Header>
          <Accordion.Body>
            <CustomChart
              path="/assets/data/tyt/caused_pain.json"
              initialType="pie"
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </ProjectContainer>
  );
};

export default ProjectPageTyt;
