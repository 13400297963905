import { Container } from "react-bootstrap";
import "./style.css";

const Privacy = () => {
  return (
    <Container className="privacy">
      <h1 className="mt-5">Privacy</h1>

      <h3 className="mt-5">General Provisions</h3>

      <p>
        With this privacy policy, we inform you about which data the website
        processes and which rights you are entitled to as a user on the basis of
        applicable legal provisions.
      </p>
      <p>
        According to Art. 4 No. 1 of the General Data Protection Regulation
        (DSGVO), personal data is any information that refers relating to an
        identified or identifiable natural person.
      </p>
      <p>
        Through our website is in principle no storage of personal data such as
        name, address, e-mail address, IP address, telephone number, SIM card or
        the name of the phone.
      </p>
      <h3 className="mt-5">
        Name and contact details of the data controller and the data protection
        officer
      </h3>
      <p>
        This privacy policy concerns data processing in the context of the use
        of our website. Responsible for data protection is:
      </p>
      <p>
        <b>Responsible:</b>
        <br />
        Julius-Maximilians-University of Würzburg (JMU)
        <br />
        Sanderring 2<br />
        97070 Würzburg
      </p>
      <p>
        Phone: <a href="tel:+49931310">+49-(0)931-31-0</a>
        <br />
        Fax: <a href="fax:+499313182600">+49-(0)931-31-82600</a>
        <br />
        E-Mail: <a href="mailto:info@uni-wuerzburg.de">info@uni-wuerzburg.de</a>
      </p>
      <p>
        <b>Data Protection Officer:</b>
        <br />
        Data Protection Officer of the University
        <br />
        Sanderring 2<br />
        97070 Würzburg
      </p>
      <p>
        Phone: <a href="tel:+49931310 ">+49-(0)931-31-0</a>
        <br />
        Fax: <a href="fax:+499313182600 ">+49-(0)931-31-82600</a>
        <br />
        E-Mail:&nbsp;
        <a href="mailto:datenschutz@uni-wuerzburg.de">
          datenschutz@uni-wuerzburg.de
        </a>
      </p>
      <p>
        <b>
          The responsible person in the context of the use of the website is:
        </b>
        <br />
        Prof. Dr. Rüdiger Pryss
      </p>
      <p>
        Professorship for Medical Informatics at the Institute of Clinical
        Epidemiology and Biometry at the University of Würzburg <br />
        Am Schwarzenberg 15 <br />
        97078 Würzburg
      </p>
      <p>
        Phone: <a href="tel:+4993120146471">+49-(0) 931-201-46471</a>
        <br />
        E-Mail:&nbsp;
        <a href="mailto:ruediger.pryss@uni-wuerzburg.de">
          ruediger.pryss@uni-wuerzburg.de
        </a>
      </p>
      <p>
        Contact the data protection officer:&nbsp;
        <a href="mailto:datenschutz@uni-wuerzburg.de">
          datenschutz@uni-wuerzburg.de
        </a>
      </p>
      <p>
        If you have any questions about data protection law or your data subject
        rights, you can contact our data protection officer.
      </p>
      <h3 className="mt-5">Data subject rights</h3>
      <p>You have the right:</p>
      <ul>
        <li>
          in accordance with Art. 7 (3) DSGVO to revoke your consent once given
          to us at any time. This has consequence that we may no longer continue
          the data processing, which was based on this consent, for the future.
          continue in the future;
        </li>
        <li>
          pursuant to Art. 15 DSGVO to request information about your personal
          data processed by us. In particular, you can request information about
          the processing purposes, the category of personal data, the categories
          of recipients to whom your data has been or will be disclosed, the
          planned storage period, the existence of a right to rectification,
          erasure, restriction of processing or objection, the existence of a
          right of appeal, the origin of your data, if it has not been collected
          by us have been collected by us;
        </li>
        <li>
          in accordance with Art. 16 DSGVO to correct incorrect or complete your
          personal data stored by us without delay. personal data stored by us;
        </li>
        <li>
          demand the deletion of your personal data stored by us in accordance
          with Art. 17 DSGVO, insofar as processing is not necessary for the
          exercise of the right to freedom of expression and information, for
          the fulfilment legal obligation, for reasons of public interest or for
          the assertion, exercise or defense of or defense of legal claims;
        </li>
        <li>
          demand the restriction of the processing of your personal data in
          accordance with Art. 18 DSGVO, insofar as the accuracy of the data is
          contested by you, the processing is unlawful, but you object to its
          erasure and we no longer need the data, but you need them for the
          assertion, exercise or defense of legal claims or you have objected to
          the processing in accordance with Art. 21 DSGVO; or you have have
          lodged an objection;
        </li>
        <li>
          according to Art. 20 DSGVO to receive your personal data that you have
          provided to us in a structured structured, commonly used and
          machine-readable format or to request the transfer to another
          responsible party;
        </li>
        <li>
          complain to a supervisory authority in accordance with Art. 77 DSGVO.
          As a rule, you can contact the supervisory authority of your usual
          place of residence or workplace or our company headquarters. contact.
        </li>
      </ul>
      <p>
        Information about your right to object according to Art. 21 DSGVO You
        have the right to object at any time, on grounds relating to your
        particular situation, to the processing of personal data relating to you
        which is carried out on the basis of Article 6 (1) p. 1 lit. e DSGVO
        (data processing in the public interest) and Article 6 para. 1 p. 1 lit.
        f DSGVO (data processing based on a basis of a balance of interests). If
        you lodge an objection, we will no longer process your personal data,
        unless we can demonstrate compelling legitimate grounds for the
        processing which override your interests, rights and freedoms, or the
        processing serves the assertion, exercise or defense of legal claims. If
        you wish to exercise your right to object, please contact the Data
        Protection Officer:&nbsp;
        <a href="mailto:datenschutz@uni-wuerzburg.de">
          datenschutz@uni-wuerzburg.de
        </a>
        .
      </p>
    </Container>
  );
};

export default Privacy;
