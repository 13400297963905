export class DataGroupModel {
  #label = '';
  #index = -1;


  get label() {
    return this.#label;
  }

  get index() {
    return this.#index;
  }

  /**
   *
   * @param {String} label
   * @param {Number} index
   */
  constructor({label = '', index = -1} = {}) {
    this.#index = index;
    this.#label = label;
  }

}