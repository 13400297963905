import React from "react";
import { Card, Container, Button, Row, Col } from "react-bootstrap";

import { ReactComponent as AndroidBadge } from "../../assets/images/android_badge.svg";
import { ReactComponent as IosBadge } from "../../assets/images/ios_badge.svg";

const CustomCard = ({ image, title, subtitle, links }) => {
  return (
    <Card className="mb-4">
      <Card.Img variant="top" height={200} src={image} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{subtitle}</Card.Text>
        <hr />
        <Button className="w-100">Project Page</Button>
        <Container className="m-0 p-0 mt-4">
          <Row>
            <Col>
              <a href={links.android} target="_blank">
                <AndroidBadge />
              </a>
            </Col>
            <Col>
              <a href={links.ios} target="_blank">
                <IosBadge />
              </a>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default CustomCard;
