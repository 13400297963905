import { Context } from "svgcanvas";
import Chart from "chart.js/auto";
import { jsPDF } from "jspdf";

export class DownloadService {
  /**
   * Transform chart canvas into an image file representation
   * and start a download process.
   *
   * @param {String} id
   * @param {JSON} settings
   * @param {String} filetype
   */
  static download = (id, settings, filetype = "PNG") => {
    if (isValid(settings)) {
      tweakLibrary();

      let link = document.createElement("a");

      switch (filetype.toUpperCase()) {
        case "PNG":
          link.href = toPng(id, settings);
          break;
        case "PDF":
          link.href = toPdf(id, settings);
          return;
        case "SVG":
          link.href = toSvg(id, settings);
          break;

        default:
          console.warn(
            `File format ${filetype} not suported. Please try one of the following: PDF, PNG, SVG`
          );
          return;
      }

      link.download = `chart.${filetype.toLowerCase()}`;
      link.click();
    }
  };
}

const toPng = (id, settings) => {
  let chartCanvas = document.getElementById(id);

  let canvas = document.createElement("canvas");
  canvas.width = 1600;
  canvas.height = (chartCanvas.offsetHeight / chartCanvas.offsetWidth) * 1600;

  let ctx = canvas.getContext("2d");
  let chart = new Chart(ctx, settings);

  return chart.toBase64Image();
};

const toSvg = (id, settings) => {
  // get the dimensions of our original chart
  let chartCanvas = document.getElementById(id);
  let width = 1600;
  let height = (chartCanvas.offsetHeight / chartCanvas.offsetWidth) * 1600;

  // create an svg version of the chart
  let svgContext = new Context(width, height);

  new Chart(svgContext, settings);

  let pre = "data:image/svg+xml;charset=utf-8,";
  let svg = svgContext.getSerializedSvg();

  return `${pre}${encodeURIComponent(svg)}`;
};

const toPdf = (id, settings) => {
  // get the dimensions of our original chart
  let chartCanvas = document.getElementById(id);
  let height = (chartCanvas.offsetHeight / chartCanvas.offsetWidth) * 1600;

  // console.info(`Height: ${height}, Width: 1600`);

  const doc = new jsPDF("l", "pt", [1600, height]);

  doc.addImage(toPng(id, settings), 0, 0, 1600, height);

  // doc.addSvgAsImage(toSvg(id, settings), 0, 0, 1600, 1600);

  doc.save("chart.pdf");
  return "";
  // return doc.output("datauri");
};

const isValid = (settings) => {
  if (settings.options.animation !== false) {
    console.warn("Please disable animations.");
    return false;
  }
  if (settings.options.responsive !== false) {
    console.warn("Please disable responsiveness.");
    return false;
  }
  return true;
};

const tweakLibrary = () => {
  Context.prototype.getContext = function (contextId) {
    if (contextId === "2d" || contextId === "2D") {
      return this;
    }
    return null;
  };
  Context.prototype.style = function () {
    return this.__canvas.style;
  };
  Context.prototype.getAttribute = function (name) {
    return this[name];
  };
  Context.prototype.addEventListener = function (
    type,
    listener,
    eventListenerOptions
  ) {
    // nothing to do here, but we need this function :)
  };
};
