import Footer from "./components/Footer";
import Navigation from "./components/Navbar";
import CustomRoutes from "./components/Routes";
import "./App.scss";
const App = () => {
  return (
    <>
      <Navigation />
      <CustomRoutes />
      <Footer />
    </>
  );
};

export default App;
