import React from "react";
import Container from "react-bootstrap/Container";

const ProjectContainer = ({ children, title, description }) => {
  return (
    <Container className="mt-4" style={{ backgroundColor: "#f1f3f6" }}>
      <p className="m-0 text-secondary">PROJECT</p>
      <h1>{title}</h1>

      <p className="m-0 text-secondary">DESCRIPTION</p>
      <p>{description}</p>

      {children}
    </Container>
  );
};

export default ProjectContainer;
