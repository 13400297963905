import colorVariables from '../variables.scss';
export class ColorService {
  static getColors() {
    return colorVariables['chartColors'].map(color => `""`);
  }

  /**
   * Convert a hex string into its rgba string-representation.
   * After: https://gist.github.com/danieliser/b4b24c9f772066bcf0a6
   *
   * @param {String} hexString
   * @param {Number} opacity
   * @returns {String}
   */
  static hexToRGBA(hexString, opacity = 1.0) {
    if (opacity < 0 || opacity > 1) {
      console.error(`Opcaity level out of bounds [0, 1]: ${opacity}`);
      // Use 1.0 as default opacity.
      opacity = 1.0;
    }

    hexString = hexString.replace('#', '');

    if (hexString.length === 3) {
      hexString = `${hexString[0]}${hexString[0]}${hexString[1]}${hexString[1]}${hexString[2]}${hexString[2]}`;
    }

    const r = parseInt(hexString.substring(0, 2), 16);
    const g = parseInt(hexString.substring(2, 4), 16);
    const b = parseInt(hexString.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity})`;
  }

  /**
   * Returns a hex color string. Maps the numeric value to an index fitting in the array of defined colors (see variables.scss).
   *
   * @param value
   * @return {string}
   */
  static getPredefinedColor(value) {
    const colors = colorVariables['chartColors']
      .replaceAll('[', '')
      .replaceAll(']', '')
      .split(',')
      .map(color => `${color}`.trim());
    const index = value % colors.length;
    return colors[index];
  }

  /**
   * Returns a continuous color scale, starting and ending at the provided colors using x steps.
   * @param {Number} value
   * @param {Number} [minValue]
   * @param {Number} [maxValue]
   * @param {String} [defaultColor]
   * @param {String} startColor
   * @param {String} endColor
   * @return {String}
   */
  static getInterpolatedColor(value = 0, {minValue = 0, maxValue = 100, defaultColor = '#000', startColor = '#ff0000', endColor = '#0000ff'} = {}) {

    if (maxValue <= minValue || value < minValue || value > maxValue) {
      console.error(`Check your min- (${minValue}), max- (${maxValue}) and target (${value}) values!`);
      return defaultColor;
    }

    // Map value to color scale with respect to min- and max-values.
    const distance = Math.abs(minValue - maxValue);
    const ratio = (value - minValue) / distance;

    startColor = Number('0x' + `${startColor.replaceAll('#', '')}`.trim());
    endColor = Number('0x' + `${endColor.replaceAll('#', '')}`.trim());

    // Some magic from: https://gist.github.com/nikolas/b0cce2261f1382159b507dd492e1ceef
    const ar = (Number(startColor) & 0xFF0000) >> 16,
      ag = (Number(startColor) & 0x00FF00) >> 8,
      ab = (Number(startColor) & 0x0000FF),

      br = (Number(endColor) & 0xFF0000) >> 16,
      bg = (Number(endColor) & 0x00FF00) >> 8,
      bb = (Number(endColor) & 0x0000FF),

      rr = ar + ratio * (br - ar),
      rg = ag + ratio * (bg - ag),
      rb = ab + ratio * (bb - ab);

    return `#${((rr << 16) + (rg << 8) + (rb | 0)).toString(16)}`;
  }
}