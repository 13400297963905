import { Container } from "react-bootstrap";

const About = () => {
  return (
    <Container>
      <h1 className="mt-5">About</h1>
      <p>&copy; {new Date().getFullYear()} – The dashboard developer team</p>
      <p>University of Würzburg:</p>
      <ul>
        <li>
          <a
            href="https://www.linkedin.com/in/allgaier-johannes"
            target="_blank"
          >
            Johannes Allgaier
          </a>
          &nbsp;(Data Scientist)
        </li>
        <li>
          <a
            href="https://www.med.uni-wuerzburg.de/epidemiologie/institut/mitarbeiter/carsten-vogel/"
            target="_blank"
          >
            Carsten Vogel
          </a>
          &nbsp;(Full stack developer)
        </li>
        <li>
          <a
            href="
            https://www.med.uni-wuerzburg.de/epidemiologie/institut/mitarbeiter/prof-dr-ruediger-pryss/"
            target="_blank"
          >
            Prof. Dr. Rüdiger Pryss
          </a>
          &nbsp;(Supervisor)
        </li>
      </ul>
      <p>University of Ulm:</p>
      <ul>
        <li>
          <a
            href="https://www.linkedin.com/in/jens-scheible-a32553171"
            target="_blank"
          >
            Jens Scheible
          </a>
          &nbsp;(Frontend developer)
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/fabian-hofmann-2b1a60193/"
            target="_blank"
          >
            Fabian Hofmann
          </a>
          &nbsp;(Frontened developer)
        </li>
      </ul>
      <p>
        Learn more about the dashboard project in our&nbsp;
        <a
          href="https://medicalinformatics.ike-b.de/index.html"
          target="_blank"
        >
          video!
        </a>
      </p>
    </Container>
  );
};

export default About;
