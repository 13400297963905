import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { DownloadService } from "../../services/download-service";
import options from "./defaults.json";

const DownloadButton = ({ chartId, type, data }) => {
  const handleClick = (filetype) => {
    let settings = {
      type: type,
      data: data,
      options: options,
    };

    DownloadService.download(chartId, settings, filetype);
  };

  useEffect(() => {}, [data]);

  return (
    <Dropdown>
      <Dropdown.Toggle>Download as</Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleClick("PDF")}>PDF</Dropdown.Item>
        <Dropdown.Item onClick={() => handleClick("PNG")}>PNG</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DownloadButton;
