const DateIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
      <path
        fill="current"
        d="M24 28.15q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.25t1.25-.5q.75 0 1.25.5t.5 1.25q0 .7-.5 1.225-.5.525-1.25.525Zm-8 0q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.25t1.25-.5q.75 0 1.25.5t.5 1.25q0 .7-.5 1.225-.5.525-1.25.525Zm16 0q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.25t1.25-.5q.75 0 1.25.5t.5 1.25q0 .7-.5 1.225-.5.525-1.25.525ZM24 36q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.25t1.25-.5q.75 0 1.25.5t.5 1.25q0 .7-.5 1.225Q24.75 36 24 36Zm-8 0q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.25t1.25-.5q.75 0 1.25.5t.5 1.25q0 .7-.5 1.225Q16.75 36 16 36Zm16 0q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.25t1.25-.5q.75 0 1.25.5t.5 1.25q0 .7-.5 1.225Q32.75 36 32 36ZM9.95 42.85q-1.15 0-1.975-.85-.825-.85-.825-1.95V11.2q0-1.15.825-1.975Q8.8 8.4 9.95 8.4h3.75V6.1q0-.5.35-.85t.85-.35q.55 0 .9.35t.35.85v2.3H31.9V6.05q0-.5.35-.825.35-.325.85-.325t.85.35q.35.35.35.8V8.4h3.75q1.15 0 1.975.825.825.825.825 1.975v28.85q0 1.1-.825 1.95-.825.85-1.975.85Zm0-2.2h28.1q.25 0 .425-.175t.175-.425V20.3H9.35v19.75q0 .25.175.425t.425.175Zm-.6-22.55h29.3v-6.9q0-.25-.175-.425t-.425-.175H9.95q-.25 0-.425.175t-.175.425Zm0 0v-7.5 7.5Z"
      />
    </svg>
  );
};

export default DateIcon;
