import { useState, useEffect } from "react";
import CustomChart from "../../components/Chart";
import ProjectContainer from "../../components/ProjectContainer";
import { Accordion } from "react-bootstrap";
import Facts from "../../components/Facts/Facts";

const ProjectPageUniti = () => {
  const [facts, setFacts] = useState();

  useEffect(() => {
    fetch("/assets/data/uniti/uniti.json")
      .then((temp) => {
        return temp.json();
      })
      .then((data) => {
        setFacts(data);
      })
      .catch((error) => {
        // error if file is not written yet
        // console.log(error);
      });
  }, []);

  return (
    <ProjectContainer
      title="UNITI"
      description="Tinnitus is the perception of a phantom sound and the patient’s reaction to it. Although much progress has been made, tinnitus remains a scientific and clinical enigma of high prevalence and high economic burden.
The overall aim of Unification of Treatments and Interventions for Tinnitus Patients (hereafter UNITI) is to deliver a predictive computational model based on existing and longitudinal data attempting to address the question which treatment approach is optimal for a specific patient based on specific parameters. Clinical, epidemiological, medical, genetic and audiological data, including signals reflecting ear-brain communication, will be analysed from existing databases. Predictive factors for different patient groups will be extracted and their prognostic relevance will be tested in a randomized controlled trial (RCT) in which different groups of patients will undergo a combination of therapies targeting the auditory and central nervous systems. The project has received funding from the European Union's Horizon 2020 Research and Innovation Programme, Grant Agreement Number 848261."
    >
      {facts && <Facts data={facts} />}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Part A - Distribution of practitioner visits
          </Accordion.Header>
          <Accordion.Body>
            <CustomChart
              path="/assets/data/sod/doctor_visits_by_severity.json"
              initialType="pie"
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </ProjectContainer>
  );
};

export default ProjectPageUniti;
