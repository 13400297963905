import { Container } from "react-bootstrap";

const Imprint = () => {
  return (
    <Container className="mt-5">
      <h1>Imprint</h1>
      <p>
        Information according to § 5 Telemedia Act and § 55 Interstate
        Broadcasting Treaty.
      </p>
      <h3 className="mt-5">Address:</h3>
      <p>
        Julius-Maximilians-University of Würzburg
        <br />
        Sanderring 2<br />
        97070 Würzburg
      </p>
      <p>
        Phone: <a href="tel:+49931310">+49-(0)931-31-0</a>
        <br />
        Fax: <a href="fax:+499313182600">+49-(0)931-31-82600</a>
      </p>
      <h3 className="mt-5">Authorized to represent</h3>
      <p>
        Julius-Maximilians-Universität Würzburg is legally represented by the
        President Prof. Dr. Paul Pauli.
      </p>
      <h3 className="mt-5">Competent supervisory authority</h3>
      <p>
        Bavarian State Ministry for Science and Art
        <br />
        Salvatorstraße 2<br />
        80327 München
      </p>
      <h3 className="mt-5">Value added tax identification number</h3>
      <p>
        The sales tax identification number according to § 27a Sales Tax Act:
        DE134187690.
      </p>
      <h3 className="mt-5">
        Responsible for the content and design in the sense of § 55 para. 2 RStV
      </h3>
      <p>Prof. Dr. Rüdiger Pryss</p>
      <p>
        Professorship for Medical Informatics at the Institute of Clinical
        Epidemiology and Biometry
        <br />
        Am Schwarzenberg 15
        <br />
        97078 Würzburg
      </p>
      <p>
        Phone <a href="tel:+4993120146471">+49-(0) 931-201-46471</a>
        <br />
        E-Mail:&nbsp;
        <a href="mailto:ruediger.pryss@uni-wuerzburg.de">
          ruediger.pryss@uni-wuerzburg.de
        </a>
      </p>
    </Container>
  );
};

export default Imprint;
