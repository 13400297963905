import { ChartData } from "chart.js";

export class DataService {
  /**
   * Get the string or JSON-object content of a file from its path. Parameter asObject determines whether to return an
   * object or not.
   *
   * @param {String} path
   * @param {Boolean} asObject
   * @returns {Promise<String|Object>}
   */
  static getFileContent(path, asObject = false) {
    return fetch(path)
      .then((content) => (asObject ? content.json() : content.text()))
      .catch((error) => console.debug(error));
  }

  /**
   * Tries to give the value representation back its original data type.
   *
   * @param value
   * @returns {String|Number|null}
   */
  static applyCorrectDataType(value) {
    if (value === null || value === "null") {
      return null;
    }

    const numberValue = Number(value);
    if (!isNaN(numberValue)) {
      return numberValue;
    }

    return `${value.replaceAll('"', "")}`;
  }

  /**
   * A helper function to convert csv-strings to objects
   *
   * @param {String} inputString
   * @param {String} lineDelimiter
   * @param {String} elementDelimiter
   */
  static csvToObject(
    inputString,
    { lineDelimiter = "\n", elementDelimiter = "," } = {}
  ) {
    // Get the header row from the topmost CSV-file line of content.
    const firstLineEndIndex = inputString.indexOf(lineDelimiter);
    // Regex according to: https://stackoverflow.com/questions/21105360/regex-find-comma-not-inside-quotes
    const elementDelimiterRegex = new RegExp(
      `(?!\\B"[^"]*)${elementDelimiter}(?![^"]*"\\B)`
    );
    const headerElements = inputString
      .slice(0, firstLineEndIndex)
      .split(elementDelimiterRegex)
      .map((elementString) => this.applyCorrectDataType(elementString));

    // After removing the header from CSV-file content, split up the remaining string into an array (=rows) of arrays (=elements)
    const rowElements = inputString
      .slice(firstLineEndIndex + 1)
      .split(lineDelimiter)
      .map((rowStrings) => {
        return rowStrings
          .split(elementDelimiter)
          .map((elementString) => this.applyCorrectDataType(elementString));
      });

    const objectToReturn = {};
    objectToReturn["header"] = headerElements;
    objectToReturn["data"] = rowElements;

    return objectToReturn;
  }

  /**
   * Transforms an absolute chart data set into one with relative values.
   *
   * @param {ChartData} chartData
   * @return {any}
   */
  static absoluteToRelativeData(chartData) {
    const copiedData = JSON.parse(JSON.stringify(chartData));

    // The sum of each first entry of each dataset equals 100%, so sum them up!
    const totalAmounts = [];
    for (let i = 0; i < copiedData.labels.length; i++) {
      totalAmounts[i] = 0;
      copiedData.datasets.forEach((dataSet) => {
        totalAmounts[i] += Number(dataSet.data[i]);
      });
    }

    copiedData.datasets = copiedData.datasets.map((dataSet) => {
      return {
        ...dataSet,
        data: dataSet.data.map(
          (dataValue, index) => dataValue / totalAmounts[index]
        ),
      };
    });
    return copiedData;
  }
}
