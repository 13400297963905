import { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import CustomChart from "../../components/Chart";
import ProjectContainer from "../../components/ProjectContainer";
import Facts from "../../components/Facts/Facts";

const ProjectPageCC = () => {
  const [facts, setFacts] = useState();

  useEffect(() => {
    fetch("/assets/data/cc/cc.json")
      .then((temp) => {
        return temp.json();
      })
      .then((data) => {
        setFacts(data);
      })
      .catch((error) => {
        // error if file is not written yet
        // console.log(error);
      });
  }, []);

  return (
    <ProjectContainer
      title="Corona Check"
      description="Do participants currently have a reasonable suspicion of an infection with the coronavirus? With the Corona Check app, participants can assess this quickly. After a few questions in a short assessment, participants get feedback based on their answers of the assessment."
    >
      {facts && <Facts data={facts} />}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Part Α – Individual characteristics
          </Accordion.Header>
          <Accordion.Body>
            <CustomChart
              path="/assets/data/cc/covid_results_by_sth.json"
              initialType="pie"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Part Α – Individual characteristics
          </Accordion.Header>
          <Accordion.Body>
            <CustomChart
              path="/assets/data/cc/symptoms_count.json"
              initialType="pie"
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </ProjectContainer>
  );
};

export default ProjectPageCC;
