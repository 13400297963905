import {DataGroupModel} from "./data-group.model";

export class DataSetModel {
  #groups = [];
  #aggregations = []
  #label = '';

  get label() {
    return this.#label;
  }

  get groups() {
    return this.#groups;
  }

  get aggregations() {
    return this.#aggregations;
  }

  /**
   *
   * @param {String} label
   * @param {DataGroupModel[]} groups
   * @param {DataAggregationModel[]} aggregations
   */
  constructor({label = '', groups = [], aggregations = []} = {}) {
    this.#label = label;
    this.#groups = groups;
    this.#aggregations = aggregations;
  }
}