const UserIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
      <path
        fill="current"
        d="M5.15 38.4q-.6 0-1-.375T3.75 37v-2.3q0-1.55.8-2.75T6.8 30.1q3.25-1.4 5.9-2.1 2.65-.7 5.9-.7 3.3 0 5.925.7t5.875 2.1q1.45.65 2.25 1.85.8 1.2.8 2.75V37q0 .65-.375 1.025-.375.375-1.025.375Zm30.65 0q.4-.1.675-.475t.275-.975V34.8q0-2.65-1.25-4.425T32.25 27.5q2.65.4 5 1.05T41.2 30q1.4.8 2.25 2 .85 1.2.85 2.8V37q0 .65-.425 1.025-.425.375-.975.375ZM18.6 23.35q-2.85 0-4.7-1.825t-1.85-4.675q0-2.9 1.85-4.725 1.85-1.825 4.7-1.825 2.9 0 4.725 1.825 1.825 1.825 1.825 4.725 0 2.85-1.825 4.675Q21.5 23.35 18.6 23.35Zm15.55-6.55q0 2.85-1.825 4.7T27.6 23.35q-.35 0-.7-.05t-.7-.15q1.1-1.15 1.65-2.75.55-1.6.55-3.6 0-1.95-.575-3.475Q27.25 11.8 26.2 10.45q.3-.05.7-.1.4-.05.7-.05 2.9 0 4.725 1.825Q34.15 13.95 34.15 16.8ZM5.95 36.2H31.3v-1.5q0-.8-.475-1.5T29.5 32.05q-3.15-1.45-5.5-2t-5.4-.55q-3 0-5.375.55t-5.525 2q-.85.45-1.3 1.15-.45.7-.45 1.5Zm12.65-15q1.9 0 3.125-1.25t1.225-3.1q0-1.9-1.225-3.125T18.6 12.5q-1.85 0-3.1 1.225T14.25 16.8q0 1.9 1.225 3.15T18.6 21.2Zm0-4.35Zm0 12.65Z"
      />
    </svg>
  );
};

export default UserIcon;
