import {DataSetModel} from "../models/data-set.model";
import {ColorService} from "./color-service";
import {ChartTypeRegistry} from 'chart.js';

export class ChartMappingService {
  /*
   *
   * @param {DataSetModel} dataSetModel
   * @param {keyof ChartTypeRegistry} type
   * @param {Boolean} isGroupedByGroup
   * @return {{labels, datasets}[]}
   */
  static dataSetToChartByType(dataSetModel, type, isGroupedByGroup = false) {

    if (!this.#isSupportedType(type)) {
      throw new Error(`Chart type "${type}" is currently not supported!`);
    }

    return isGroupedByGroup ?
      this.#getGroupByAggregationsData(dataSetModel) :
      this.#getGroupByGroupsData(dataSetModel);
  }

  /**
   *
   * @param {keyof ChartTypeRegistry} type
   * @return {boolean}
   */
  static #isSupportedType(type) {
    const supportedTypes = ['bar', 'line', 'pie'];
    return supportedTypes.includes(type);
  }

  /**
   *
   * @param {DataSetModel} dataSetModel
   * @return {{datasets: *, labels: *}[]}
   */
  static #getGroupByGroupsData(dataSetModel) {
    const labels = dataSetModel.groups.map((group) => group.label);
    return dataSetModel.aggregations
      .map((aggregation) => {
        const optionsLength = aggregation.options.length;
        const datasets = aggregation.options
          .map((option, optionIndex) => {
            const color = aggregation.isOrdinalColorScale ?
              ColorService.getInterpolatedColor(optionIndex, {minValue: 0, maxValue: optionsLength}) :
              ColorService.getPredefinedColor(optionIndex);
            return {
              label: option.label,
              data: option.counts,
              borderColor: color,
              backgroundColor: ColorService.hexToRGBA(color, 0.7)
            };
          });

        return {
          labels, datasets
        };
      });
  }

  /**
   *
   * @param {DataSetModel} dataSetModel
   * @return {{datasets: *, labels: *}[]}
   */
  static #getGroupByAggregationsData(dataSetModel) {

    const groupsLength = dataSetModel.groups.length;
    return dataSetModel.aggregations
      .map((aggregation) => {
        const labels = aggregation.options.map((option) => option.label);
        const datasets = dataSetModel.groups
          .map((group, groupIndex) => {
            const color = aggregation.isOrdinalColorScale ?
              ColorService.getInterpolatedColor(groupIndex, {minValue: 0, maxValue: groupsLength}) :
              ColorService.getPredefinedColor(groupIndex);
            return {
              label: group.label,
              data: aggregation.options.map((option) => option.counts[groupIndex]),
              borderColor: color,
              backgroundColor: ColorService.hexToRGBA(color, 0.7)
            };
          });

        return {labels, datasets};
      });
  }
}