import React from "react";
import { Container, Row, Col, NavLink } from "react-bootstrap";
import CustomCard from "../../components/Card";

import projects from "../../assets/data/projects.json";

const Dashboard = () => {
  return (
    <Container className="mt-5 flex-grow-1">
      <Row>
        <Col xs={12}>
          <h1>Welcome,</h1>
        </Col>
        <Col xs={12}>
          <p>
            to the app data dashboard, proudly presented by the Julius
            Maximilian University of Würzburg.
          </p>
        </Col>
      </Row>
      <Row>
        {projects.map((project, index) => (
          <Col xs={12} md={3} key={index}>
            <a
              href={`projects${project.internallink}`}
              style={{ textDecoration: "none", color: "black" }}
            >
              <CustomCard
                image={project.image}
                title={project.title}
                subtitle={project.description}
                links={{ android: project.playstore, ios: project.appstore }}
              />
            </a>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Dashboard;
