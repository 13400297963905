import React from "react";
import { Navbar, Container, Nav, NavDropdown, Button } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";

import projects from "../../assets/data/projects.json";

const Navigation = () => {
  return (
    <Navbar bg="white" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/">
          <Logo height={40} width={90} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="Projects" id="basic-nav-dropdown">
              {projects.map((project, index) => {
                return (
                  <NavDropdown.Item
                    href={`/projects${project.internallink}`}
                    key={index}
                  >
                    {project.title}
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>
            <Nav.Link href="/about">About</Nav.Link>
          </Nav>
          <Nav>
            {/*<Button
              variant="primary"
              style={{ backgroundColor: "#004188", borderColor: "#004188" }}
            >
              Login
            </Button>*/}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
