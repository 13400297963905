import AnswerIcon from "./answer/AnswerIcon";
import DateIcon from "./date/DateIcon";
import UserIcon from "./user/UserIcon";

const Icon = ({ name }) => {
  return <>{getIconBy(name)}</>;
};

const getIconBy = (name) => {
  switch (name) {
    case "answer":
      return <AnswerIcon />;
    case "user":
      return <UserIcon />;
    case "date":
      return <DateIcon />;
  }
};

export default Icon;
