import { DataSetModel } from "../models/data-set.model";
import { DataOptionModel } from "../models/data-option.model";
import { DataGroupModel } from "../models/data-group.model";
import { DataAggregationModel } from "../models/data-aggregation.model";

class MappingHelper {
  static getFieldContent(jsonObject, fieldName) {
    const valueToReturn = jsonObject[fieldName];
    if (valueToReturn === undefined) {
      throw new Error(
        `JSON object does not include field "${fieldName}":\n${JSON.stringify(
          jsonObject
        )}`
      );
    }
    return valueToReturn;
  }

  static getStringRepresentation(objectOrString) {
    return typeof objectOrString === "string"
      ? objectOrString
      : this.getFieldContent(objectOrString, "label");
  }
}

export class DataMappingService {
  /**
   * Creates and returns a DataSet model out of the provided JSON-object.
   *
   * @param {object} jsonObject
   * @param {string} groupByKey
   * @param {string} aggregationsKey
   * @param {string} optionsKey
   * @param {string} optionsCountKey
   * @param {Object} configLabel
   * @return {DataSetModel}
   */
  static mapJsonToDatasetObject(
    jsonObject,
    {
      groupByKey = "group_by",
      aggregationsKey = "aggregate_by",
      optionsKey = "options",
      optionsCountKey = "options_count",
      configLabel = "config",
    } = {}
  ) {
    const dataSetLabel = jsonObject.label;
    const groups = jsonObject[groupByKey].map((label, groupIndex) => {
      return new DataGroupModel({ label, index: groupIndex });
    });
    const aggregations = Object.keys(jsonObject[aggregationsKey]).map(
      (aggregationLabel) => {
        const aggregationObject = jsonObject[aggregationsKey][aggregationLabel];
        const config = aggregationObject[configLabel];
        const aggregation = new DataAggregationModel({
          label: aggregationLabel,
          config,
        });
        const optionCounts = aggregationObject[optionsCountKey];
        const options = aggregationObject[optionsKey].map(
          (optionString, optionIndex) => {
            const combinedCounts = [];
            optionCounts.forEach((countsArray) => {
              combinedCounts.push(countsArray[optionIndex]);
            });

            return new DataOptionModel(aggregation, {
              label: optionString,
              counts: combinedCounts,
            });
          }
        );
        aggregation.options = options;

        return aggregation;
      }
    );
    return new DataSetModel({ groups, aggregations, label: dataSetLabel });
  }
}
