import React from "react";
import "./style.scss";

const Footer = () => {
  return (
    <footer>
      <hr style={{ margin: "1em" }} />
      <p className="text-center">
        Copyright &copy; {new Date().getFullYear()}&nbsp;
        <a
          href="https://www.med.uni-wuerzburg.de/epidemiologie/startseite/"
          target="_blank"
        >
          Institute of Clinical Epidemiology and Biometry,
          Julius-Maximilians-University of Würzburg (JMU)
        </a>
        <br />
        <a className="legal" href="/privacy">
          Privacy
        </a>
        <a className="legal" href="/imprint">
          Imprint
        </a>
      </p>
    </footer>
  );
};

export default Footer;
