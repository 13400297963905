import Grid from "../Grid/Grid";
import Icon from "../../icons/Icon";
import "./Facts.css";

const Facts = ({ data }) => {
  return (
    <Grid>
      {data.map((fact, index) => {
        return (
          <Grid.Item key={index} className="fact">
            <div className="fact-text">
              <span className="fact-text-title">{fact.title}</span>
              <span className="fact-text-value">{fact.value}</span>
            </div>
            <div className="fact-icon">
              <Icon name={fact.icon} />
            </div>
          </Grid.Item>
        );
      })}
    </Grid>
  );
};

export default Facts;
