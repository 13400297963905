export class DataOptionModel {
  #label = '';
  #counts = [];
  #parentAggregation = null;


  get label() {
    return this.#label;
  }

  get counts() {
    return this.#counts;
  }

  get parentAggregation() {
    return this.#parentAggregation;
  }

  set parentAggregation(value) {
    // TODO: make more robust
    this.#parentAggregation = value;
  }

  /**
   *
   * @param {DataAggregationModel} parentAggregation
   * @param {String} label
   * @param {Number[]} counts
   */
  constructor(parentAggregation, {label = '', counts = []}) {
    this.#label = label;
    this.#counts = counts;
    this.#parentAggregation = parentAggregation;
  }
}