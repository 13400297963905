import { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import CustomChart from "../../components/Chart";
import ProjectContainer from "../../components/ProjectContainer";
import Facts from "../../components/Facts/Facts";

const ProjectPageCH = () => {
  const [facts, setFacts] = useState();

  useEffect(() => {
    fetch("/assets/data/ch/ch.json")
      .then((temp) => {
        return temp.json();
      })
      .then((data) => {
        setFacts(data);
      })
      .catch((error) => {
        // error if file is not written yet
        // console.log(error);
      });
  }, []);

  return (
    <ProjectContainer
      title="Corona Health"
      description="The studies are used to evaluate the impact of the covid-19 pandemic on mental and physical health. The results should show the need for improvement in the health systems and in individual coping skills. The outcome should also be used for recommendations to decrease negative impacts, which arise through the pandemic itself as well as through the steps to curb it."
    >
      {facts && <Facts data={facts} />}
      <h2 className="mt-5 mb-3">Mental health for adolescents </h2>
      <p>
        This scientific study investigates the burden on adolescents worldwide
        aged 12-17 years during the coronavirus-pandemic. Understanding the
        participant’s struggles may help physicians and scientists to develop
        strategies to better cope with the pandemic. No tracking is done by the
        app and only the data that participants agree to will be transmitted
        anonymously.
      </p>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Part Α – Individual characteristics
          </Accordion.Header>
          <Accordion.Body>
            <CustomChart
              path="/assets/data/ch/children_baseline/daily_life_since_pandemic.json"
              initialType="pie"
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <h2 className="mt-5 mb-3">Physical health for adults</h2>
      <p>
        In this study, we would like to learn more about how the Corona crisis
        affects the participant's habits with a focus on factors that are
        related to the cardiovascular system. All data is collected anonymously.
      </p>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Part Α – Individual characteristics
          </Accordion.Header>
          <Accordion.Body>
            <CustomChart
              path="/assets/data/ch/heart_baseline/sport_since_pandemic.json"
              initialType="pie"
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <h2 className="mt-5 mb-3">Mental health for adults</h2>
      <p>
        While physical illness is prominent in news and the public, we are
        interested in the mood and possible stress factors that influence it.
        Moving freely, daily work routine, eating out, visiting grandparents, or
        meeting friends are important ingredients for us to feel good.
        Consequently, we would like to learn more about how participants
        maintain social contacts, as these are important factors related to
        well-being.
      </p>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Part Α – Quality of life</Accordion.Header>
          <Accordion.Body>
            <CustomChart
              path="/assets/data/ch/parent_baseline/quality_of_life.json"
              initialType="pie"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Part B – Worries catching covid</Accordion.Header>
          <Accordion.Body>
            <CustomChart
              path="/assets/data/ch/parent_baseline/worries_catching_covid_by_sth.json"
              initialType="pie"
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <h2 className="mt-5 mb-3">Perceived stress in times of Covid-19</h2>
      <p>
        How does the Sars-CoV-2 pandemic affect the stress experience of adults?
        Are there any differences in gender, age, or educational level in this
        regard? Do any temporal trends become apparent? This is a survey on
        stress experiences of adults (e.g., how often you have felt nervous and
        stressed in the last week).
      </p>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Part Α – Individual characteristics
          </Accordion.Header>
          <Accordion.Body>
            <CustomChart
              path="/assets/data/ch/children_baseline/daily_life_since_pandemic.json"
              initialType="pie"
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </ProjectContainer>
  );
};

export default ProjectPageCH;
