import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartTypeRegistry,
  ArcElement,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { v4 } from "uuid";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import DownloadButton from "../DownloadButton";
import "./style.scss";
import { ChartMappingService } from "../../services/chart-mapping.service";
import { DataService } from "../../services/data-service";
import { DataMappingService } from "../../services/data-mapping-service";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const options = {
  animation: true,
  responsive: true,
  interaction: {
    intersect: false,
    axis: "x",
    mode: "nearest",
  },
  plugins: {
    legend: {
      position: "top",
    },
  },
};

/**
 *
 * @param {DataSetModel} dataSet
 * @param {keyof ChartTypeRegistry} initialType
 * @returns {JSX.Element}
 * @constructor
 */
const CustomChart = ({ type: initialType = "bar", path }) => {
  const [type, setType] = useState(initialType);
  const [title, setTitle] = useState("");
  const [isGroupedByGroup, setIsGroupedByGroup] = useState(false);
  const [chartIndex, setChartIndex] = useState(0);
  const [dataSet, setDataSet] = useState(null);
  const [chartsData, setChartsData] = useState([]);
  const [configuration, setConfiguration] = useState({ ...options });
  const [id, setId] = useState(v4());

  const chartTypes = [
    { label: "Bar Chart", value: "bar" },
    { label: "Line Chart", value: "line" },
    // { label: "Pie Chart", value: "pie" },
  ];

  useEffect(() => {
    if (dataSet) {
      setTitle(dataSet.label);
      setChartsData(
        ChartMappingService.dataSetToChartByType(
          dataSet,
          type,
          isGroupedByGroup
        )
      );
    } else {
      loadDataSet(path);
    }
  }, [dataSet, isGroupedByGroup, type]);

  function onSelectAggregation(event) {
    setChartIndex(Number(event.target.value));
  }

  function onSwitchGroupBy(event) {
    setIsGroupedByGroup(event.target.checked);
  }

  function onChangeChartType(event) {
    setType(event.target?.value ?? "bar");
  }

  function loadDataSet(path) {
    DataService.getFileContent(`${process.env.PUBLIC_URL}${path}`, true).then(
      (jsonObject) => {
        if (jsonObject) {
          const datasetModel =
            DataMappingService.mapJsonToDatasetObject(jsonObject);
          setDataSet(datasetModel);
        }
      }
    );
  }

  return (
    <>
      <Container>
        {chartsData?.length > 0 ? (
          <>
            <h3 className="text-center">{title}</h3>
            <p className="text-center text-muted"></p>
            <Row>
              <Col className="text-center">
                <label>
                  <div className="d-inline p-1">Aggregated by</div>
                  <select onChange={onSelectAggregation} className="d-inline">
                    {dataSet.aggregations.map(
                      (aggregation, aggregationIndex) => {
                        return (
                          <option
                            key={aggregationIndex}
                            value={aggregationIndex}
                          >
                            {aggregation.label}
                          </option>
                        );
                      }
                    )}
                  </select>
                </label>
              </Col>
              <Col className="text-center">
                <label>
                  <Form.Check
                    type="switch"
                    checked={isGroupedByGroup}
                    onChange={onSwitchGroupBy}
                    className="d-inline"
                  />
                  <div className="d-inline p-1">
                    {`Grouped by ${isGroupedByGroup ? "aggregation" : "group"}`}
                  </div>
                  <select onChange={onChangeChartType}>
                    {chartTypes.map(({ label, value }) => {
                      return (
                        <option key={label} value={value}>
                          {label}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </Col>
              <Col className="text-center">
                <DownloadButton
                  chartId={id}
                  type={initialType}
                  data={chartsData[chartIndex]}
                />
              </Col>
            </Row>
            <Row>
              <Chart
                className="custom-chart"
                id={id}
                data={chartsData[chartIndex]}
                options={configuration}
                redraw={true}
                type={type}
              />
            </Row>
          </>
        ) : (
          <div className="loading-spinner--container">
            <Spinner animation="grow" size="sm" />
            <span>Loading...</span>
          </div>
        )}
      </Container>
    </>
  );
};

export default CustomChart;
