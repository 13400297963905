import {DataOptionModel} from "./data-option.model";

export class DataAggregationModel {
  #label = '';
  #options = [];
  #config = {};
  // TODO: Create in a more reusable way.
  #isOrdinalColorScale = false;

  get label() {
    return this.#label;
  }

  get options() {
    return this.#options;
  }

  set options(value) {
    // TODO: make more robust
    this.#options = value;
  }

  get isOrdinalColorScale() {
    return this.#isOrdinalColorScale;
  }

  /**
   *
   * @param {String} label
   * @param {DataOptionModel[]} options
   * @param {Object} config
   */
  constructor({label = '', options = [], config = {}} = {}) {
    this.#label = label;
    this.#options = options;
    this.#config = config;
    this.#isOrdinalColorScale = config?.['color_scale'] === 'ordinal';
  }
}